<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { offlineProject, onlineProject, userStore } from '@/main';
import { projectDefaults } from '@/structures';
import BoxModelPanel from '@/components/panels/BoxModelPanel.vue';
import CanvasPanel from '@/components/panels/CanvasPanel.vue';
import EditorPanel from '@/components/panels/EditorPanel.vue';
import Icon from '@/components/Icon.vue';
import ResizeGroup from '@/components/ResizeGroup.vue';
import ToggleInput from '@/components/ToggleInput.vue';

const canvasPanelRatio = { min: 0.2, max: 1.8 };
const editorPanelRatio = { min: 0.2, max: 1.8 };
const projectReference = computed(() => userStore.authenticated ? onlineProject : offlineProject);
const showBoxModel = ref(true);
const verticalEditorArrangement = ref(false);
let mobileQuery: MediaQueryList;

function updateMobileArrangement (query: MediaQueryList | MediaQueryListEvent): void {
	verticalEditorArrangement.value = query.matches;
	if (query.matches) showBoxModel.value = false;
}

onMounted(() => {
	// Arrange the editor vertically if the viewport width is too small.
	mobileQuery = window.matchMedia('only screen and (max-width: 50rem)');
	updateMobileArrangement(mobileQuery);
	mobileQuery.addEventListener('change', updateMobileArrangement);
});

onUnmounted(() => {
	mobileQuery.removeEventListener('change', updateMobileArrangement);
});
</script>

<template>
	<section class="panels">
		<ResizeGroup
			:vertical="verticalEditorArrangement"
			:min="editorPanelRatio.min"
			:max="editorPanelRatio.max"
			:default="projectDefaults.editorRatio"
			v-model="projectReference.editorRatio"
		>
			<template v-slot:first>
				<EditorPanel />
			</template>
			<template v-slot:second>
				<ResizeGroup
					vertical
					:hide="showBoxModel ? 0 : 2"
					:min="canvasPanelRatio.min"
					:max="canvasPanelRatio.max"
					:default="projectDefaults.canvasRatio"
					v-model="projectReference.canvasRatio"
				>
					<template v-slot:first>
						<CanvasPanel>
							<template v-slot:settings>
								<label
									for="showBoxModel"
									class="canvasSetting"
									:title="showBoxModel ? 'Hide box model' : 'Show box model'"
								>
									<Icon outline>dialogs</Icon>
									<ToggleInput name="showBoxModel" v-model="showBoxModel" />
								</label>
							</template>
						</CanvasPanel>
					</template>
					<template v-slot:second>
						<BoxModelPanel />
					</template>
				</ResizeGroup>
			</template>
		</ResizeGroup>
	</section>
</template>

<style scoped>
.panels {
	grid-area: panels;
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	max-width: unset;
	max-height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.canvasSetting {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5ch;
	height: 100%;
	padding: 0.25em;
	font-size: 0.8rem;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);
	cursor: pointer;
	
	& > span {
		font-size: 1.25rem;
	}

	&:has(:focus-visible) {
		outline: auto;
	}
}

@media only screen and (max-width: 50rem) {
	.panels {
		flex-flow: column nowrap;
		height: 100%;
	}
}
</style>
