<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { offlineProject, onlineProject, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { wall } from '@/modules/firebase/walls';
import router from '@/router';
import { RPCResponse } from '@/structures';
import DownloadMenu from '@/components/DownloadMenu.vue';
import EditorWizard from '@/components/EditorWizard.vue';
import ProjectSettingsMenu from '@/components/ProjectSettingsMenu.vue';
import ShareMenu from '@/components/ShareMenu.vue';
import CreateAside from './Aside.vue';
import CreateNavBar from './NavBar.vue';
import CreatePanels from './Panels.vue';
import { RPC } from '@/modules/firebase/rpc';

const projectReference = computed(() => userStore.authenticated ? onlineProject : offlineProject);
const projectSettingsDialog = ref({ show: false, project: '' });
const showDownload = ref(false);
const showShare = ref(false);

function confirmCreateNewProject (): boolean {
	const message = 'You have unsaved changes. Are you sure you want to create a new project?';
	return projectReference.value.css?.length <= 0 || window.confirm(message);
}

async function createNewProject (): Promise<void> {
	Firebase.loading.value = true;
	const response = await RPC(RPC.Endpoint.createProject, {});
	Firebase.loading.value = false;
	if (response.status === RPCResponse.SubscriptionRequired().status) {
		wall.premium();
	}
	if (response.success && response.message) {
		onlineProject.select(response.message);
	}
}

function openDownloadMenu (): void {
	wall.message = 'Login to download this project.';
	if (!wall.authenticated()) return;
	showDownload.value = true;
}

function openShareMenu (): void {
	wall.message = 'Login to share this project.';
	if (!wall.multiple([wall.authenticated, wall.verified])) return;
	showShare.value = true;
}

function openProjectSettings (projectId: string): void {
	projectSettingsDialog.value.project = projectId;
	projectSettingsDialog.value.show = true;
}

function saveProject (): void {
	Firebase.projectBatcher.flush(onlineProject.id);
}

function handleKeybinds (event: KeyboardEvent): void {
	// Allow dialogs to be closed with the `Escape` key.
	if (event.code === 'Escape') {
		showDownload.value = false;
		showShare.value = false;
	}
	// Allow the project to be saved with `[Ctrl|Meta]+S`.
	if (event.code === 'KeyS' && (event.ctrlKey || event.metaKey)) {
		event.preventDefault();
		saveProject();
	}
}

watch(() => router.currentRoute.value.params.id, id => {
	if (!userStore.authenticated) {
		router.replace('/create');
		return;
	}
	if (typeof id === 'string') {
		onlineProject.select(id);
	}
});

watch(
	() => onlineProject.id,
	id => {
		if (!id) return;
		if (!router.currentRoute.value.params.id) {
			router.replace(`/create/${onlineProject.id}`);
			return;
		}
		router.push(`/create/${onlineProject.id}`);
	},
	{ immediate: true }
);

watch(
	() => userStore.authenticated,
	authenticated => {
		if (authenticated) return;
		router.replace('/create');
	},
	{ immediate: true }
);

onMounted(() => {
	window.addEventListener('keydown', handleKeybinds);
	// Save the project if the user leaves the window.
	document.addEventListener('mouseleave', saveProject);
	document.addEventListener('visibilitychange', () => { document.visibilityState === 'hidden' && saveProject(); });
});

onUnmounted(() => {
	window.removeEventListener('keydown', handleKeybinds);
});
</script>

<template>
	<main :class="{ showProjectLinks: userStore.authenticated }">
		<CreateNavBar
			@new="confirmCreateNewProject() && createNewProject()"
			@download="openDownloadMenu()"
			@save="saveProject()"
			@share="openShareMenu()"
		/>

		<CreateAside
			@new="createNewProject()"
			@settings="openProjectSettings($event)"
		/>

		<CreatePanels />
	</main>

	<ProjectSettingsMenu
		:project="projectSettingsDialog.project"
		v-model="projectSettingsDialog.show"
	/>
	<DownloadMenu
		v-if="onlineProject.id"
		:project="onlineProject.id"
		v-model="showDownload"
	/>
	<ShareMenu
		v-if="onlineProject.id"
		:project="onlineProject.id"
		v-model="showShare"
	/>
	<EditorWizard />
</template>

<style scoped>
main {
	--gap: 8px;
	--nav-height: 2rem;
	--aside-width: 17.8rem;
	--aside-height: 4rem;

	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: var(--nav-height) calc(100vh - var(--nav-height) - var(--gap) * 3);
	grid-template-rows: var(--nav-height) calc(100dvh - var(--nav-height) - var(--gap) * 3);
	grid-template-areas:
		'nav   nav'
		'aside panels';
	gap: var(--gap) 0;
	height: 100vh;
	height: 100dvh;
	padding: var(--gap);
	background-color: #eee;
	user-select: none;
}
html.dark main {
	background-color: var(--color-background-dark);
}

@media only screen and (max-width: 80rem) {
	main {
		grid-template-columns: 1fr;
		grid-template-rows: var(--nav-height) auto calc(100vh - var(--nav-height) - var(--gap) * 3);
		grid-template-rows: var(--nav-height) auto calc(100dvh - var(--nav-height) - var(--gap) * 3);
		grid-template-areas:
			'nav'
			'aside'
			'panels';
	}
	main.showProjectLinks {
		grid-template-rows: var(--nav-height) auto calc(100vh - var(--nav-height) - var(--aside-height) - var(--gap) * 4);
		grid-template-rows: var(--nav-height) auto calc(100dvh - var(--nav-height) - var(--aside-height) - var(--gap) * 4);
	}
}

@media only screen and (max-width: 50rem) {
	main {
		--gap: 4px;
	}
}
</style>
