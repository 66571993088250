/**
 * The core response structure of an RPC.
 * An RPC will always return this information.
 */
export interface RPCResponseCore {
	status: number;
	success: boolean;
}

type RPCResponseMessage<M> =
	M extends string
	? RPCResponseCore & { message: M }
	: RPCResponseCore;

type RPCResponseData<D> =
	D extends null | undefined
	? RPCResponseCore
	: RPCResponseCore & { data: D };

/**
 * The response structure of an RPC.
 */
export type RPCResponseObject<M extends string | undefined = undefined, D extends unknown = undefined> =
	RPCResponseMessage<M> & RPCResponseData<D>;

/**
 * Create a standardised RPC response object.
 * @param status The HTTP status code of the response.
 * @param success Set `true` to indicate the response was successful, `false` to indicate unsuccessful.
 * @param message _(Optional)_ A message to include in the response object.
 * @param data _(Optional)_ Data to include in the response object.
 * @returns The RPC response object.
 */
function response                                   (status: number, success: boolean):                               RPCResponseObject<undefined, undefined>;
function response <M extends string | undefined>    (status: number, success: boolean, message: M):                   RPCResponseObject<M, undefined>;
function response <D>                               (status: number, success: boolean, message: undefined, data: D):  RPCResponseObject<undefined, D>;
function response <M extends string | undefined, D> (status: number, success: boolean, message: M, data: D):          RPCResponseObject<M, D>;
function response <M extends string | undefined, D> (status: number, success: boolean, message?: M, data?: D):        RPCResponseObject<M, D>;
function response <M extends string | undefined, D> (status: number, success: boolean, message?: M, data?: D):        RPCResponseObject<M, D> {
	const response: RPCResponseCore = { status, success };
	if (typeof message === 'string') Object.assign(response, { message });
	if (data != undefined) Object.assign(response, { data });
	return response as RPCResponseObject<M, D>;
}

type PreConfiguredResponse = {
	                                  ():                            RPCResponseObject<undefined, undefined>;
	<M extends string | undefined>    (message: M):                  RPCResponseObject<M, undefined>;
	<D>                               (message: undefined, data: D): RPCResponseObject<undefined, D>;
	<M extends string | undefined, D> (message: M, data: D):         RPCResponseObject<M, D>;
	<M extends string | undefined, D> (message?: M, data?: D):       RPCResponseObject<M, D>;
	<M extends string | undefined, D> (message?: M, data?: D):       RPCResponseObject<M, D>;
}

/**
 * A standardised RPC response.
 */
export const RPCResponse = {
	Custom:                      response,
	/** `200`: The request was successful. */
	Success:                     ((message: any, data: any) => response(200, true, message, data)) as PreConfiguredResponse,
	/** `303`: The response to the request can be found elsewhere. */
	SeeOther:                    ((message: any, data: any) => response(303, true, message, data)) as PreConfiguredResponse,
	/** `400`: The request is invalid. */
	BadRequest:                  ((message: any, data: any) => response(400, false, message, data)) as PreConfiguredResponse,
	/** `402`: A subscription is required to access this resource. */
	SubscriptionRequired:        ((message: any, data: any) => response(402, false, message, data)) as PreConfiguredResponse,
	/** `403`: An account is required to access this resource. */
	AccountRequired:             ((message: any, data: any) => response(403, false, message, data)) as PreConfiguredResponse,
	/** `404`: The requested resource cannot be found. */
	NotFound:                    ((message: any, data: any) => response(404, false, message, data)) as PreConfiguredResponse,
	/** `409`: This request conflicts with the current state of the resource. */
	ResourceStateConflict:       ((message: any, data: any) => response(409, false, message, data)) as PreConfiguredResponse,
	/** `413`: The request payload is too large. */
	TooLong:                     ((message: any, data: any) => response(413, false, message, data)) as PreConfiguredResponse,
	/** `449`: A verified account is required to access this resource. */
	AccountVerificationRequired: ((message: any, data: any) => response(449, false, message, data)) as PreConfiguredResponse,
	/** `499`: The request was cancelled by the client. */
	ClientCancelledRequest:      ((message: any, data: any) => response(499, false, message, data)) as PreConfiguredResponse,
	/** `500`: The request failed due to a server error. */
	ServerError:                 ((message: any, data: any) => response(500, false, message, data)) as PreConfiguredResponse,
	/** `503`: The requested resource is unavailable. */
	Unavailable:                 ((message: any, data: any) => response(503, false, message, data)) as PreConfiguredResponse
} as const;
