import type { DataConverterRX, DataConverterTX } from './meta';
import { matchedAssign } from './other';

export enum ImageFormat {
	JPG,
	PNG,
	SVG,
	WEBP
}

export enum TaskStatus {
	Pending,
	InProgress,
	Done,
	Cancelled
}

export class Task {
	time: number;
	status = TaskStatus.Pending;

	constructor () {
		this.time = Date.now();
	}

	static fromFirestore: DataConverterRX<Task> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new Task(), data);
	}

	static toFirestore: DataConverterTX<Task> = (task) => {
		return { ...task };
	}
}

export class ImageRenderTask extends Task {
	height: number;
	format: ImageFormat;
	projectId: string;
	versionId: string;
	width: number;

	constructor (projectId: string, versionId: string, format: ImageFormat, width: number, height: number) {
		super();
		this.height = height;
		this.format = format;
		this.projectId = projectId;
		this.versionId = versionId;
		this.width = width;
	}

	static fromFirestore: DataConverterRX<Task> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new ImageRenderTask('', '', ImageFormat.PNG, 0, 0), data);
	}

	static toFirestore: DataConverterTX<Task> = (task) => {
		return { ...task };
	}
}

export class ImageRenderResult {
	time: number;
	url: string;

	constructor (url: string) {
		this.time = Date.now();
		this.url = url;
	}

	static fromFirestore: DataConverterRX<ImageRenderResult> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new ImageRenderResult(''), data);
	}

	static toFirestore: DataConverterTX<ImageRenderResult> = (task) => {
		return { ...task };
	}
}

export class ThumbnailRenderTask extends ImageRenderTask {
	constructor (projectId: string, versionId: string) {
		super(projectId, versionId, ImageFormat.WEBP, 960, 540);
	}

	static fromFirestore: DataConverterRX<Task> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new ThumbnailRenderTask('', ''), data);
	}

	static toFirestore: DataConverterTX<Task> = (task) => {
		return { ...task };
	}
}
