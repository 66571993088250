import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/Home.vue';
import LoginView from '@/views/Login.vue';
import RegisterView from '@/views/Register.vue';
import BrowseView from '@/views/Browse.vue';
import CreateView from '@/views/Create/Page.vue';
import { onlineProject, userStore } from '@/main';
import { action } from '@/modules/firebase/actions';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import { awaitChange } from '@/utilities';

const titles: Record<string, string> = {
	'account':          'Account Settings',
	'browse':           'Browse',
	'collection':       'Collection',
	'collection-short': 'Collection',
	'create':           'Editor',
	'create-default':   'Editor',
	'delete-account':   'Delete Account',
	'document':         'Document',
	'home':             'Home',
	'login':            'Login',
	'profile':          'Profile',
	'profile-default':  'Your Profile',
	'profile-short':    'Profile',
	'project':          'Project',
	'project-short':    'Project',
	'register':         'Sign Up',
	'reset-password':   'Reset Password',
	'signup':           'Sign Up',
	'sitemap':          'Sitemap',
	'verify':           'Verification',
};

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		return savedPosition ?? { top: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
			beforeEnter: to => {
				// Handle special account actions.
				const apiKey = typeof to.query.apiKey === 'string' ? to.query.apiKey : undefined;
				const continueUrl = typeof to.query.continueUrl === 'string' ? to.query.continueUrl : undefined;
				const language = typeof to.query.lang === 'string' ? to.query.lang : '';
				const mode = typeof to.query.mode === 'string' ? to.query.mode : '';
				const oobCode = typeof to.query.oobCode === 'string' ? to.query.oobCode : '';
				void action({ apiKey, continueUrl, language, mode, oobCode, router });
			}
		},
		{
			path: '/login',
			name: 'login',
			component: LoginView
		},
		{
			path: '/register',
			name: 'register',
			component: RegisterView
		},
		{
			path: '/signup',
			name: 'signup',
			component: RegisterView
		},
		{
			path: '/verify-email',
			name: 'verify',
			component: () => import('@/views/Verify.vue')
		},
		{
			path: '/browse',
			name: 'browse',
			component: BrowseView
		},
		{
			path: '/project',
			name: 'project-redirect',
			redirect: { path: '/browse' }
		},
		{
			path: '/p',
			name: 'project-short-redirect',
			redirect: { path: '/browse' }
		},
		{
			path: '/project/:id',
			name: 'project',
			component: () => import('@/views/Project/Page.vue')
		},
		{
			path: '/p/:id',
			name: 'project-short',
			component: () => import('@/views/Project/Page.vue')
		},
		{
			path: '/collection',
			name: 'collection-redirect',
			redirect: { path: '/browse' }
		},
		{
			path: '/collection/:id',
			name: 'collection',
			component: () => import('@/views/Collection.vue')
		},
		{
			path: '/create',
			name: 'create-default',
			component: CreateView
		},
		{
			path: '/create/new',
			name: 'new',
			beforeEnter: () => {
				userStore.autoLogin
					.then(() => {
						Firebase.loading.value = true;
						return RPC(RPC.Endpoint.createProject, {});
					})
					.then((response) => {
						if (response.success && response.message) onlineProject.select(response.message);
						Firebase.loading.value = true;
					});
				return { path: '/create' };
			},
			component: CreateView
		},
		{
			path: '/new',
			name: 'new-redirect',
			redirect: { path: '/create/new' }
		},
		{
			path: '/create/:id',
			name: 'create',
			beforeEnter: to => {
				const projectId = to.params.id;
				if (typeof projectId === 'string') {
					userStore.autoLogin
						.then(() => awaitChange(Firebase.loading, false))
						.then(() => {
							if (userStore.authenticated) {
								onlineProject.select(projectId);
							}
						});
				}
				return true;
			},
			component: CreateView
		},
		{
			path: '/@:handle/:tab',
			name: 'profile-short',
			component: () => import('@/views/Profile.vue')
		},
		{
			path: '/@:handle',
			name: 'profile-short-redirect',
			beforeEnter: to => {
				return `/@${to.params.handle}/projects`;
			},
			component: () => import('@/views/Profile.vue')
		},
		{
			path: '/profile/:id/:tab',
			name: 'profile',
			component: () => import('@/views/Profile.vue')
		},
		{
			path: '/profile/:id',
			name: 'profile-redirect',
			beforeEnter: to => {
				return `/profile/${to.params.id}/projects`;
			},
			component: () => import('@/views/Profile.vue')
		},
		{
			path: '/profile',
			name: 'profile-default',
			component: () => import('@/views/Profile.vue')
		},
		{
			path: '/account',
			name: 'account',
			component: () => import('@/views/Account.vue')
		},
		{
			path: '/delete-account',
			name: 'delete-account',
			component: () => import('@/views/DeleteAccount.vue')
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: () => import('@/views/ResetPassword.vue')
		},
		{
			path: '/document',
			name: 'document-redirect',
			redirect: { name: 'sitemap' }
		},
		{
			path: '/document/:document',
			name: 'document',
			component: () => import('@/views/Document.vue')
		},
		{
			path: '/sitemap',
			name: 'sitemap',
			component: () => import('@/views/Sitemap.vue')
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'catch-all',
			redirect: { path: '/' }
		}
	]
});

// Update the page title.
router.afterEach(async (to) => {
	const plain = 'Cascades';
	if (!to.name || typeof to.name === 'symbol' || to.name === 'home') {
		document.title = plain;
		return;
	}
	const suffix = ' | Cascades';
	const prefix = titles[to.name];
	if (!prefix) {
		document.title = plain;
		return;
	}
	switch (to.name) {
		case 'profile':
		case 'profile-short':
			if (typeof to.params.id === 'string') {
				const userInfo = await Firebase.publicUsers.get(to.params.id);
				document.title = (userInfo?.displayName || prefix) + suffix;
				break;
			}
		case 'project':
		case 'project-short':
			if (typeof to.params.id === 'string') {
				const project = await Firebase.publicProjects.get(to.params.id);
				document.title = (project?.title || prefix) + suffix;
				break;
			}
		case 'collection':
		case 'collection-short':
			if (typeof to.params.id === 'string') {
				const collection = await Firebase.publicCollections.get(to.params.id);
				document.title = (collection?.title || prefix) + suffix;
				break;
			}
		default:
			document.title = prefix + suffix;
			break;
	}
});

export default router;
