<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { offlineProject, onlineProject, userStore } from '@/main';
import HTMLGenerator from '@/modules/editor/generator';
import { Flavour } from '@/structures';
import Icon from '@/components/Icon.vue';

const generator = new HTMLGenerator();
const mainOutput = ref<HTMLDivElement | null>(null);
const zoomDelta = 0.1;
const projectReference = computed(() => userStore.authenticated ? onlineProject : offlineProject);
const backgroundColor = computed((): string => projectReference.value.backgroundColor);
let zoomInterval: number;
let zoomTimeout: number;

function poll (): void {
	if (projectReference.value.css) {
		void generator.set(projectReference.value.css);
		return;
	}
	window.setTimeout(poll, 500);
}

function startZoomRoll (direction: 'in' | 'out'): void {
	zoomTimeout = window.setTimeout(() => {
		zoomInterval = window.setInterval(() => {
			projectReference.value.zoom += direction === 'in' ? zoomDelta : -zoomDelta;
		}, 100);
	}, 250);
}

function stopZoomRoll (): void {
	window.clearInterval(zoomInterval);
	window.clearTimeout(zoomTimeout);
}

watch(() => projectReference.value.css, css => { void generator.set(css); poll(); }, { immediate: true });
watch(() => projectReference.value.flavour, flavour => { generator.flavour.value = flavour as Flavour; }, { immediate: true });
onMounted(() => mainOutput.value && generator.applyShadowDom(mainOutput.value));
</script>

<template>
	<div class="canvasPanel" data-journey-id="CanvasPanel">
		<div class="canvasPanelInner" :style="{ backgroundColor }">
			<div class="canvasSettings" data-journey-id="CanvasSettings">
				<label for="canvasBackgroundColor" class="canvasBackgroundColor" title="Background color">
					<Icon>colors</Icon>
					<div class="colorPicker">
						<input
							type="color"
							id="canvasBackgroundColor"
							name="canvasBackgroundColor"
							v-model="projectReference.color"
						>
					</div>
				</label>
				<label for="canvasBackgroundAlpha" class="canvasBackgroundAlpha" title="Background opacity">
					<Icon>opacity</Icon>
					<input
						type="range"
						id="canvasBackgroundAlpha"
						name="canvasBackgroundAlpha"
						:min="0"
						:max="1"
						:step="0.01"
						v-model="projectReference.opacity"
					>
					<input
						type="number"
						id="canvasBackgroundAlpha"
						name="canvasBackgroundAlpha"
						:min="0"
						:max="1"
						:step="0.1"
						v-model="projectReference.opacity"
					>
				</label>
				<slot name="settings"></slot>
				<div class="zoomControls">
					<button
						title="Zoom out"
						@click="projectReference.zoom -= zoomDelta"
						@pointerdown="startZoomRoll('out')"
						@pointerup="stopZoomRoll()"
						@pointercancel="stopZoomRoll()"
						@pointerleave="stopZoomRoll()"
					>
						<Icon>remove</Icon>
					</button>
					<output>{{ (projectReference.zoom * 100).toFixed(0) }}%</output>
					<button
						title="Zoom in"
						@click="projectReference.zoom += zoomDelta"
						@pointerdown="startZoomRoll('in')"
						@pointerup="stopZoomRoll()"
						@pointercancel="stopZoomRoll()"
						@pointerleave="stopZoomRoll()"
					>
						<Icon>add</Icon>
					</button>
				</div>
			</div>
			<div
				ref="mainOutput"
				class="output"
				:style="{ transform: `scale(${projectReference.zoom * 100}%)` }"
			></div>
		</div>
	</div>
</template>

<style scoped>
.canvasPanel {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	user-select: none;
}

.canvasPanelInner {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	height: 100%;
	border-radius: var(--border-radius-large);
	overflow: hidden;
}

.canvasSettings {
	z-index: 2;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
	gap: 0.5ch;
	width: 100%;
	padding: 0.5ch;
	background-color: var(--color-background);
	border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
	overflow: auto hidden;
}

.canvasSettings label {
	flex-shrink: 0
}
.canvasSettings label:has(:focus-visible) {
	outline: auto;
}

.canvasSettings input {
	flex-shrink: 0
}

.canvasBackgroundColor {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5ch;
	height: 100%;
	padding: 0.25em;
	font-size: 1.25rem;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);
	cursor: pointer;
}
.colorPicker {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1em;
	height: 1em;
	border: 1px solid currentColor;
	overflow: hidden;
	cursor: pointer;
}
.colorPicker > input[type="color"] {
	position: absolute;
	width: 180%;
	height: 180%;
	padding: 0;
	cursor: pointer;
}

.canvasBackgroundAlpha {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5ch;
	height: 100%;
	padding: 0.25em;
	font-size: 1.25rem;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);
}
.canvasBackgroundAlpha input[type="range"] {
	max-width: 10ch;
}
.canvasBackgroundAlpha input[type="number"] {
	max-width: 8ch;
	color: var(--color-text);
	font-size: revert;
	background-color: var(--color-background);
}

.zoomControls {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 2px;
	height: 100%;
	margin-left: auto;
	font-size: small;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);
}
.zoomControls > button {
	min-width: 3ch;
	height: -webkit-fill-available;
}
.zoomControls > output {
	min-width: 5ch;
	text-align: center;
}

.canvasPanel .output {
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	contain: content;
	overflow: visible;
}
</style>
