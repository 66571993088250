<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { dialogStore, userStore } from '@/main';
import { gravatar } from '@/modules/gravatar';
import Icon from '@/components/Icon.vue';

const props = defineProps({
	large: { type: Boolean, required: false },
	lightHover: { type: Boolean, required: false },
	navigate: { type: Boolean, required: false },
	round: { type: Boolean, required: false },
});

const size = computed(() => props.large ? 34 : props.round ? 30 : 28);
const src = ref('');

function updateImage (): void {
	if (userStore.authenticated) {
		gravatar(size.value).then(url => { src.value = url; });
	} else {
		src.value = '';
	}
}

watch(() => userStore.authenticated, updateImage, { immediate: true });
</script>

<template>
	<div class="accountButton" :class="{ large, round: userStore.authenticated && round }" data-journey-id="AccountButton">
		<component
			:is="navigate && !userStore.authenticated ? 'RouterLink' : 'button'"
			class="button"
			:class="{ lightHover }"
			to="/login"
			:title="userStore.authenticated ? 'Account settings' : 'Login'"
			@click="navigate && !userStore.authenticated || (userStore.authenticated ? dialogStore.open(dialogStore.Dialog.Account) : dialogStore.open(dialogStore.Dialog.Login))"
		>
			<img
				v-if="userStore.authenticated && src"
				:src
				:width="size"
				:height="size"
				alt=""
			>
			<Icon v-else aria-hidden="true">login</Icon>
			<span v-if="large">{{ userStore.authenticated ? userStore.displayName ?? 'Account' : 'Login' }}</span>
		</component>
	</div>
</template>

<style scoped>
.accountButton > :is(button, .button) > img {
	width: 1.05em;
	height: 1.05em;
	border-radius: 50%;
}

.accountButton.large > :is(button, .button) {
	border: unset;
}
.accountButton.large > :is(button, .button) > img {
	width: 1.5em;
	height: 1.5em;
}

.accountButton.round > :is(button, .button) {
	padding: 0;
	border: 1px solid transparent;
	border-radius: 50%;

	&:hover {
		border-color: var(--color-border);
	}
}
.accountButton.round > :is(button, .button) > img {
	width: 1.5em;
	height: 1.5em;
}
</style>
