<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { theme, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import { wall } from '@/modules/firebase/walls';
import { gravatar } from '@/modules/gravatar';
import router from '@/router';
import { Theme } from '@/structures';
import DialogBox from '@/components/DialogBox.vue';
import Icon from '@/components/Icon.vue';
import ToggleInput from '@/components/ToggleInput.vue';
import PremiumCard from '@/components/cards/PremiumCard.vue';

const props = defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue', 'delete', 'reset', 'success', 'verify']);
const displayName = ref('');
const displayNameLoading = ref(false);
const displayNameSaved = ref(false);
const profileLink = ref('');
const profileLinkClickable = ref(true);
const profileLinkLoading = ref(false);
const profileLinkSaved = ref(false);
const profileLinkError = ref(false);
const profilePicture = ref('');
const formattedProfileLink = computed({
	get: () => profileLink.value.replace(/@+/, ''),
	set: value => profileLink.value = `@${value}`.replace(/@+/, '@')
});
const profileUrl = computed(() => `${window.location.origin}/@${formattedProfileLink.value}`);
const loading = ref(false);

function hide (): void {
	emit('update:modelValue', false);
}
async function load (): Promise<void> {
	gravatar(140).then(url => { profilePicture.value = url; });
	profileLinkLoading.value = true;
	displayName.value = userStore.displayName ?? '';
	profileLink.value = userStore.vanityLink ?? '';
	profileLinkError.value = false;
	profileLinkLoading.value = false;
	profileLinkClickable.value = true;
}
async function saveDisplayName(): Promise<void> {
	displayNameLoading.value = true;
	displayNameSaved.value = true;
	await Firebase.updateDisplayName(displayName.value);
	displayNameLoading.value = false;
	window.setTimeout(() => {
		displayNameSaved.value = false;
	}, 3000);
}
async function saveProfileLink(): Promise<void> {
	if (!userStore.subscribed) return;
	profileLinkLoading.value = true;
	profileLinkSaved.value = true;
	const response = await RPC(RPC.Endpoint.updateProfileLink, { link: profileLink.value });
	if (!response.success) {
		profileLinkError.value = true;
		profileLinkLoading.value = false;
		profileLinkSaved.value = false;
		return;
	}
	profileLinkClickable.value = true;
	profileLinkLoading.value = false;
	window.setTimeout(() => {
		profileLinkSaved.value = false;
	}, 3000);
}

watch(() => props.modelValue, (value) => value && void load());
watch(() => userStore.link, (value) => value && void load());
watch(profileLink, () => { profileLinkError.value = false; });
watch(() => userStore.authenticated, (value) => {
	value ? void load() : router.push({ name: 'login' });
});

onMounted(() => void load()); // Load account content when the menu page is navigated to from another page.
</script>

<template>
	<DialogBox
		title="Account"
		wide
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="AccountMenu"
	>
		<template v-slot:default>
			<div class="accountMenuContent">
				<form @submit.prevent="">
					<div class="avatar">
						<img
							:src="profilePicture"
							width="140"
							height="140"
							alt="Profile picture"
						>
						<a href="http://en.gravatar.com/emails/" target="_blank" class="button" title="Change your profile picture">Change</a>
					</div>
					<div class="toggles">
						<ToggleInput
							name="siteTheme"
							label="Theme"
							:title="theme.isDark ? 'Change to light mode' : 'Change to dark mode'"
							checkedIcon="dark_mode"
							uncheckedIcon="light_mode"
							checkedText="Dark"
							uncheckedText="Light"
							:options="[Theme.Light, Theme.Dark]"
							v-model="theme.current"
							data-journey-id="AccountMenuThemeToggle"
						/>
						<ToggleInput
							v-if="wall.admin()"
							name="adminMode"
							label="Admin Mode"
							:title="userStore.adminMode ? 'Disable admin mode' : 'Enable admin mode'"
							checkedText="Enabled"
							uncheckedText="Disabled"
							danger
							v-model="userStore.adminMode"
							data-journey-id="AccountMenuAdminToggle"
						/>
					</div>
					<label for="accountDisplayName">Display Name
						<div style="display: flex;flex-flow: row nowrap;gap: 1ch;">
							<input
								type="text"
								id="accountDisplayName"
								name="accountDisplayName"
								maxlength="64"
								autocomplete="nickname"
								v-model="displayName"
							>
							<button
								:title="displayNameLoading ? 'Saving…' : displayNameSaved ? 'Saved' : 'Save'"
								:disabled="displayNameLoading || displayNameSaved"
								@click.prevent="saveDisplayName()"
							>
								<span v-if="displayNameLoading" class="spinner" style="font-size: 1em;"></span>
								<Icon v-else>{{ displayNameSaved ? 'done' : 'save'}}</Icon>
							</button>
						</div>
					</label>
					<label
						for="accountProfileLink"
						:class="{ disable: !userStore.subscribed }"
						:title="!userStore.subscribed ? 'Subscribe to change your profile link' : ''"
					>Custom Profile Link
						<component
							:is="profileLinkClickable ? 'a' : 'span'"
							:href="profileLinkClickable ? profileUrl : ''"
							target="_blank"
							class="profileLinkPreview"
						>
							cascades.app/@{{ formattedProfileLink }}
						</component>
						<div class="profileLink">
							<input
								type="text"
								id="accountProfileLink"
								name="accountProfileLink"
								:style="{ border: profileLinkError ? '1px solid var(--color-danger)' : '' }"
								:readonly="!userStore.subscribed"
								maxlength="40"
								:autocomplete="!userStore.subscribed ? 'off' : 'nickname'"
								@input="profileLinkClickable = false"
								v-model="formattedProfileLink"
							>
							<button
								:title="profileLinkLoading ? 'Saving…' : profileLinkSaved ? 'Saved' : 'Save'"
								:disabled="profileLinkLoading || profileLinkSaved || !userStore.subscribed"
								@click.prevent="saveProfileLink()"
							>
								<span v-if="profileLinkLoading" class="spinner" style="font-size: 1em;"></span>
								<Icon v-else>{{ profileLinkSaved ? 'done' : 'save'}}</Icon>
							</button>
						</div>
						<span v-if="profileLinkError" style="color: var(--color-danger);font-size: x-small;">Sorry, that link is unavailable.</span>
					</label>
					<label for="accountEmail">
						<div class="row">
							<span>Email</span>
							<div
								v-if="userStore.raw?.emailVerified"
								class="row"
								style="gap: 0.5ch;color: var(--color-success);"
							>
								<Icon>done</Icon>
								<span style="font-size: small;">Verified</span>
							</div>
							<button
								v-else
								style="font-size: small;"
								:disabled="loading"
								@click.prevent="{ emit('verify'); hide(); }"
								data-journey-id="AccountMenuResetPassword"
							>Verify</button>
						</div>
						<input type="email" id="accountEmail" name="accountEmail" :value="userStore.raw?.email ?? ''" readonly>
					</label>
					<button
						class="reset"
						:disabled="loading"
						@click.prevent="{ emit('reset'); hide(); }"
						data-journey-id="AccountMenuResetPassword"
					>Reset Password</button>
					<button
						class="logout"
						:disabled="loading"
						@click.prevent="{ Firebase.logout(); hide(); }"
						data-journey-id="AccountMenuLogout"
					>
						<span v-if="loading" class="spinner"></span>
						<template v-else>Logout</template>
					</button>
				</form>

				<PremiumCard style="max-width: unset;" />
			</div>
		</template>
		<template v-slot:bottom>
			<p>No longer want this service?</p>
			<button class="danger" :disabled="loading" @click.stop="{ emit('delete'); hide(); }" data-journey-id="AccountMenuDelete">Delete account</button>
		</template>
	</DialogBox>
</template>

<style scoped>
.accountMenuContent {
	display: grid;
	grid-template-columns: repeat(2, minmax(270px, 1fr));
	gap: 2rem;
}

form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(5, auto);
	grid-template-areas:
		'avatar toggles'
		'name   name'
		'link   link'
		'email  email'
		'reset  logout';
	gap: 1.5rem;
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}
form label.disable {
	opacity: 0.5;
	user-select: none;
}
form label.disable > * {
	pointer-events: none;
}
form label .row {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 2ch;
}
form select {
	width: 100%;
	padding: 0.25em;
	font-size: 1rem;
	border: 1px solid var(--color-border);
	border-radius: var(--border-radius);
	transition: 0.1s ease background-color;
}

.avatar {
	grid-area: avatar;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: fit-content;
}
.avatar > img {
	border-radius: var(--border-radius);
	opacity: 1;
	transition: 0.1s ease opacity;
}
.avatar:hover > img {
	opacity: 0.1;
}
.avatar > a {
	position: absolute;
	opacity: 0;
	transition: 0.1s ease opacity;
}
.avatar:hover > a {
	opacity: 1;
}

.toggles {
	grid-area: toggles;
	display: flex;
	flex-flow: column nowrap;
	gap: inherit;
}

label[for="accountDisplayName"] {
	grid-area: name;
}

label[for="accountProfileLink"] {
	grid-area: link;
}

label[for="accountEmail"] {
	grid-area: email;
}

.reset {
	grid-area: reset;
}

.logout {
	grid-area: logout;
}

.profileLinkPreview {
	max-width: 29ch;
	font-size: small;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	opacity: 0.5;
	overflow: hidden;

	&:is(a):hover {
		color: var(--color-primary);
		opacity: 1;
	}
}
html.dark .profileLinkPreview:is(a):hover {
	color: var(--color-primary-light);
}

.profileLink {
	display: flex;
	flex-flow: row nowrap;
	gap: 1ch;
}

@media only screen and (max-width: 50rem) {
	.accountMenuContent {
		display: flex;
		flex-flow: column nowrap;
	}
}

@media only screen and (max-width: 28rem) {
	form {
		grid-template-rows: repeat(6, auto);
		grid-template-areas:
			'avatar toggles'
			'name name'
			'link link'
			'email email'
			'reset reset'
			'logout logout';
	}
}
</style>
