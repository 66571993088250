import type { DataConverterRX, DataConverterTX } from './meta';
import { matchedAssign } from './other';

export class Like {
	author: string;
	collectionId = '';
	commentId = '';
	profileId = '';
	projectId = '';
	time = Date.now();

	constructor (author: string) {
		this.author = author;
	}

	static collection (author: string, collection: string): Like {
		const like = new Like(author);
		like.collectionId = collection;
		return like;
	}

	static comment (author: string, project: string, comment: string): Like {
		const like = new Like(author);
		like.projectId = project;
		like.commentId = comment;
		return like;
	}

	static profile (author: string, uid: string): Like {
		const like = new Like(author);
		like.profileId = uid;
		return like;
	}

	static project (author: string, project: string): Like {
		const like = new Like(author);
		like.projectId = project;
		return like;
	}

	static fromFirestore: DataConverterRX<Like> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new Like(''), data);
	}

	static toFirestore: DataConverterTX<Like> = (like) => {
		return { ...like };
	}
}
