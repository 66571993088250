<script setup lang="ts">
import { computed, ref } from 'vue';
import Resizer from '@/components/Resizer.vue';

const props = defineProps({
	default: { type: Number, required: false, default: 1 },
	hide: { type: Number, required: false, default: 0 },
	min: { type: Number, required: false, default: 0.2 },
	max: { type: Number, required: false, default: 1.8 },
	vertical: { type: Boolean, required: false },
	modelValue: { type: Number, required: true }
});

const emit = defineEmits(['update:modelValue']);

const resizing = ref(false);
const ratio = computed({
	get: () => props.modelValue,
	set: value => emit('update:modelValue', value)
});
const first = {
	width: computed(() => {
		if (props.vertical) return '100%';
		if (props.hide === 1) return '0%';
		if (props.hide === 2) return '100%';
		return `${50 * ratio.value}%`;
	}),
	height: computed(() => {
		if (!props.vertical) return '100%';
		if (props.hide === 1) return '0%';
		if (props.hide === 2) return '100%';
		return `${50 * ratio.value}%`;
	})
}
const second = {
	width: computed(() => {
		if (props.vertical) return '100%';
		if (props.hide === 1) return '100%';
		if (props.hide === 2) return '0%';
		return `${100 - 50 * ratio.value}%`;
	}),
	height: computed(() => {
		if (!props.vertical) return '100%';
		if (props.hide === 1) return '100%';
		if (props.hide === 2) return '0%';
		return `${100 - 50 * ratio.value}%`;
	})
}

function updateResizing (value: boolean): void {
	resizing.value = value;
}
</script>

<template>
	<div class="resizeGroup" :class="{ vertical }">
		<div
			class="first"
			:class="{ hidden: hide === 1 }"
			:style="{
				width: first.width.value,
				height: first.height.value,
				transitionDuration: resizing ? '0s' : '0.4s'
			}"
		>
			<slot name="first"></slot>
		</div>
		<Resizer
			v-show="hide <= 0"
			:horizontal="vertical"
			:min
			:max
			:default
			@resizing="updateResizing"
			v-model="ratio"
		/>
		<div
			class="second"
			:class="{ hidden: hide === 2 }"
			:style="{
				width: second.width.value,
				height: second.height.value,
				transitionDuration: resizing ? '0s' : '0.4s'
			}"
		>
			<slot name="second"></slot>
		</div>
	</div>
</template>

<style scoped>
.resizeGroup {
	display: flex;
	flex-flow: row nowrap;
	gap: 0.5rem;
	width: 100%;
	height: 100%;
	transition: 0.4s ease gap;
	overflow: hidden;

	&.vertical {
		flex-flow: column nowrap;
	}

	& > :is(.first, .second) {
		transition: 0.4s ease width, 0.4s ease height, 0.4s ease opacity;

		&.hidden {
			opacity: 0;
		}
	}

	&:has(> .hidden) {
		gap: 0;
	}
}

@media only screen and (max-width: 50rem) {
	.resizeGroup {
		gap: 0.25rem;
	}
}
</style>
