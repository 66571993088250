<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { dialogStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import router from '@/router';
import { RPCResponse } from '@/structures';
import DialogBox from '@/components/DialogBox.vue';
import TagInput from '@/components/TagInput.vue';
import ToggleInput from '@/components/ToggleInput.vue';

const props = defineProps({
	modelValue: { type: Boolean, required: true },
	project: { type: String, required: true }
});

const emit = defineEmits(['update:modelValue']);

const initialVisibility = ref(Boolean(Firebase.projects.value.get(props.project)?.public));
const loading = ref(false);

const title = computed({
	get: (): string => (Firebase.projects.value.get(props.project)?.title ?? ''),
	set: (value: string) => (void RPC(RPC.Endpoint.updateProject, { projectId: props.project, data: { title: value } }))
});
const description = computed({
	get: (): string => (Firebase.projects.value.get(props.project)?.description ?? ''),
	set: (value: string) => (void RPC(RPC.Endpoint.updateProject, { projectId: props.project, data: { description: value } }))
});
const tags = computed({
	get: (): Array<string> => (Firebase.projects.value.get(props.project)?.tags ?? []),
	set: (value: Array<string>) => (void RPC(RPC.Endpoint.updateProject, { projectId: props.project, data: { tags: value } }))
});
const visibility = computed({
	get: (): boolean => (Firebase.projects.value.get(props.project)?.public ?? false),
	set: (value: boolean) => {
		const project = Firebase.projects.value.get(props.project);
		if (project) {
			project.public = value;
		}
	}
});

const hide = (): void => emit('update:modelValue', false);

async function save (): Promise<void> {
	loading.value = true;
	Firebase.projectBatcher.flush(props.project);
	if (visibility.value !== initialVisibility.value) {
		await RPC(visibility.value ? RPC.Endpoint.publishProject : RPC.Endpoint.unpublishProject, { projectId: props.project });
	}
	hide();
	loading.value = false;
}

async function deleteProject (): Promise<void> {
	loading.value = true;
	Firebase.loading.value = true;
	const response = await RPC(RPC.Endpoint.deleteProject, { projectId: props.project });
	if (response.status === RPCResponse.Success().status) {
		hide();
		router.back();
	} else {
		dialogStore.open(dialogStore.Dialog.Notification, 'Something went wrong.');
	}
	Firebase.loading.value = false;
	loading.value = false;
}

watch(() => props.modelValue, (value: boolean) => {
	if (value) initialVisibility.value = Boolean(Firebase.projects.value.get(props.project)?.public);
});
</script>

<template>
	<DialogBox
		title="Project Settings"
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="ProjectSettingsMenu"
	>
		<template v-slot:default>
			<form @submit.prevent="save()">
				<label for="projectSettingsTitle">Title
					<input type="text" id="projectSettingsTitle" name="projectSettingsTitle" maxlength="50" v-model="title">
				</label>
				<label for="projectSettingsDescription">Description
					<textarea
						type="text"
						id="projectSettingsDescription"
						name="projectSettingsDescription"
						rows="4"
						maxlength="1000"
						v-model="description"
					></textarea>
				</label>
				<label for="projectSettingsTags">Tags
					<TagInput
						name="projectSettingsTags"
						v-model="tags"
					/>
				</label>
				<ToggleInput
					name="projectSettingsVisibility"
					label="Visibility"
					checkedIcon="public"
					uncheckedIcon="lock"
					checkedText="Public"
					uncheckedText="Private"
					v-model="visibility"
				/>
				<button
					type="submit"
					:disabled="loading"
					data-journey-id="ProjectSettingsMenuSave"
				>
					<span v-if="loading" class="spinner"></span>
					<template v-else>Save</template>
				</button>
			</form>
		</template>
		<template v-slot:bottom>
			<button
				class="danger"
				:disabled="loading"
				@click.stop="deleteProject()"
				data-journey-id="ProjectSettingsMenuDelete"
			>Delete</button>
		</template>
	</DialogBox>
</template>

<style scoped>
form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}
</style>
