import { userStore } from '@/main';

/**
 * Generate a URL for the local user's Gravatar.
 * @param size The desired size of the Gravatar image.
 * @returns A Gravatar URL.
 */
export async function gravatar (size = 28): Promise<string> {
	if (!userStore.raw) {
		return gravatarFromHash('00000000000000000000000000000000', size);
	}
	const email = userStore.raw.email?.trim().toLowerCase() ?? '';
	return gravatarFromEmail(email, size);
}

/**
 * Generate a URL for the local user's Gravatar.
 * @param size The desired size of the Gravatar image.
 * @returns A Gravatar URL.
 */
export async function gravatarFromEmail (email: string, size = 28): Promise<string> {
	const encoder = new TextEncoder();
	const buffer = await crypto.subtle.digest('SHA-256', encoder.encode(email));
	const array = Array.from(new Uint8Array(buffer));
	const hash = array.map(byte => byte.toString(16).padStart(2, '0')).join('');
	return gravatarFromHash(hash, size);
}

/**
 * Generate a Gravatar URL based on the provided email hash.
 * @param hash The desired email, hashed with MD5 or SHA-256.
 * @param size The desired size of the Gravatar image.
 * @returns A Gravatar URL.
 */
export function gravatarFromHash (hash: string, size = 28): string {
	return `https://www.gravatar.com/avatar/${hash}.jpg?s=${size}&d=mp`;
}
