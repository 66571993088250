<script setup lang="ts">
import { computed } from 'vue';
import { dialogStore, offlineProject, onlineProject, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import AccountButton from '@/components/AccountButton.vue';
import AnimatedLogo from '@/components/AnimatedLogo.vue';
import Icon from '@/components/Icon.vue';

const emit = defineEmits(['download', 'new', 'save', 'share']);

const projectReference = computed(() => userStore.authenticated ? onlineProject : offlineProject);
</script>

<template>
	<nav>
		<RouterLink to="/">
			<AnimatedLogo class="logo" />
		</RouterLink>
		<label for="projectTitle" class="visuallyHidden">Project Title</label>
		<input
			type="text"
			id="projectTitle"
			name="projectTitle"
			class="projectTitle"
			maxlength="50"
			v-model="projectReference.title"
			data-journey-id="CreateProjectTitle"
		>
		<button
			v-if="!userStore.authenticated"
			title="New"
			@click="emit('new')"
			data-journey-id="CreateNew"
		>
			<Icon>add</Icon>
		</button>
		<button
			title="Download this project"
			@click="emit('download')"
			data-journey-id="Download"
		>
			<Icon>cloud_download</Icon>
		</button>
		<button
			title="Share this project"
			@click="emit('share')"
			data-journey-id="Share"
		>
			<Icon>share</Icon>
		</button>
		<button
			class="saveButton"
			:disabled="!Firebase.projectBatcher.pending.value"
			:title="Firebase.projectBatcher.pending.value ? 'Saving…' : 'Saved'"
			@click="emit('save')"
			data-journey-id="CreateSave"
		>
			<span v-if="Firebase.projectBatcher.pending.value" class="spinner slow" style="width: 1em;height: 1em;"></span>
			<Icon v-show="!Firebase.projectBatcher.pending.value">cloud_done</Icon>
			<span>{{ Firebase.projectBatcher.pending.value ? 'Saving…' : 'Saved' }}</span>
		</button>
		<div class="spacer"></div>
		<button
			title="Help"
			@click="dialogStore.open(dialogStore.Dialog.Help)"
			data-journey-id="CreateHelp"
		>
			<Icon>question_mark</Icon>
		</button>
		<AccountButton round />
	</nav>
</template>

<style scoped>
nav {
	grid-area: nav;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: var(--gap);
	padding: 0 var(--gap);
	
	& .logo {
		display: block;
		width: 2rem;
		height: 2rem;
		margin-right: var(--gap);
	}

	& .projectTitle {
		width: calc(var(--aside-width) - 2rem - var(--gap) * 3);
		padding: 0.2em 0.75ch;
		font-size: 1rem;
		border-radius: var(--border-radius);
	}

	& > .spacer {
		margin-left: auto;
	}
}

@media only screen and (max-width: 50rem) {
	nav .projectTitle {
		max-width: 20ch;
	}
}

@media only screen and (max-width: 600px) {
	nav .projectTitle {
		max-width: 15ch;
	}
	.saveButton > span:last-of-type {
		display: none;
	}
}

@media only screen and (max-width: 28rem) {
	nav .projectTitle {
		display: none;
	}
}
</style>
