import type { DataConverterRX, DataConverterTX } from './meta';
import { matchedAssign } from './other';

export const maxAllowedFreeProjects = 5;

export const subscribedStatuses = ['trialing', 'active'];

export class SubscriptionData {
	checkoutSession = '';
	customerId = '';
	subscriptionId = '';
	subscriptionStatus = '';
	subscriptionType = '';

	static fromFirestore: DataConverterRX<SubscriptionData> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new SubscriptionData(), data);
	}

	static toFirestore: DataConverterTX<SubscriptionData> = (settings) => {
		return { ...settings };
	}
}

export enum SubscriptionTier {
	Free,
	Premium
}
