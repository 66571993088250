import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { RPC } from '@/modules/firebase/rpc';
import { Theme } from '@/structures/theme';

const storageMedium = window.localStorage;
const monacoThemes = {
	[Theme.Dark]: 'vs-dark',
	[Theme.Light]: 'vs-light'
};
const initialPreference =
	window.matchMedia('(prefers-color-scheme: dark)').matches
	? Theme.Dark
	: Theme.Light;

export const useThemeStore = defineStore('theme', () => {
	const current = useStorage<Theme>('theme', ref(initialPreference), storageMedium, { listenToStorageChanges: true });
	const isDark = computed(() => current.value === Theme.Dark);
	const isLight = computed(() => current.value === Theme.Light);
	const monaco = computed(() => monacoThemes[current.value]);

	function updatePage (theme: string | null): void {
		const root = document.querySelector('html');
		switch (theme) {
			case Theme.Dark:
				root?.classList.add(Theme.Dark);
				break;
			case Theme.Light:
				root?.classList.remove(Theme.Dark);
				break;
			default:
				root?.classList.remove(Theme.Dark);
				break;
		}
	}
	updatePage(current.value);

	watch(current, theme => {
		void RPC(RPC.Endpoint.updateUser, { data: { theme } });
		updatePage(theme);
	});

	return {
		current,
		isDark,
		isLight,
		monaco
	};
});
