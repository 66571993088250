import { EarnedAchievement } from './achievement';
import type { DataConverterRX, DataConverterTX } from './meta';
import { matchedAssign } from './other';
import { SubscriptionData, SubscriptionTier } from './subscription';
import { Theme } from './theme';

export type AccountData = AccountSettings & SubscriptionData;

export class AccountInteractions {
	achievements = new Array<EarnedAchievement>();
	likes = 0;
	live = false;
	pinnedProject = '';
	pinnedVersion = '';
	views = 0;

	static fromFirestore: DataConverterRX<AccountInteractions> = (snapshot) => {
		const data = snapshot.data();
		data.achievements = data.achievements?.map(achievementData => matchedAssign(new EarnedAchievement(''), achievementData)) ?? [];
		return matchedAssign(new AccountInteractions(), data);
	}

	static toFirestore: DataConverterTX<AccountInteractions> = (metadata) => {
		const data = { ...metadata };
		data.achievements = metadata.achievements.map(achievement => EarnedAchievement.toFirestore(achievement) as EarnedAchievement);
		return data;
	}
}

export class AccountSettings {
	biography = '';
	liked = new Array<string>();
	links = new Array<string>();
	role = Role.None;
	theme = Theme.Light;

	static fromFirestore: DataConverterRX<AccountSettings> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new AccountSettings(), data);
	}

	static toFirestore: DataConverterTX<AccountSettings> = (settings) => {
		return { ...settings };
	}
}

/**
 * The information about a user that is publicly available.
 */
export interface PublicUserInfo {
	achievements: Array<EarnedAchievement>;
	biography: string | undefined;
	displayName: string | undefined;
	handle: string;
	likes: number;
	linesOfCode: number;
	links: Array<string>;
	live: boolean | string;
	pinnedProject: string | undefined;
	pinnedVersion: string | undefined;
	profilePic: string | undefined;
	role: Role;
	tier: SubscriptionTier;
}

export enum Role {
	None,
	Elevated,
	Moderator,
	Administrator
}
