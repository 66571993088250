import { httpsCallable } from 'firebase/functions';
import { flagsStore, onlineProject, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { type RPCResponseObject, type PublicUserInfo, RPCResponse as RPCResponseLiteral, ImageFormat, AccountSettings, Project, Collection, maxAllowedFreeProjects, Like } from '@/structures';
import { addDoc, collection, deleteDoc, doc, getDocs, limit, query, setDoc, updateDoc, where } from 'firebase/firestore';

enum RPCEndpoint {
	adminAddShelf = 'adminAddShelf',
	adminDeleteComment = 'adminDeleteComment',
	adminDeleteProject = 'adminDeleteProject',
	adminRemoveShelf = 'adminRemoveShelf',
	adminShadowComment = 'adminShadowComment',
	adminShadowProject = 'adminShadowProject',
	addImageTask = 'addImageTask',
	commentOnProject = 'commentOnProject',
	createCheckoutSession = 'createCheckoutSession',
	createPortalCheckoutSession = 'createPortalCheckoutSession',
	createCollection = 'createCollection',
	createProject = 'createProject',
	createUser = 'createUser',
	deleteAccount = 'deleteAccount',
	deleteCollection = 'deleteCollection',
	deleteComment = 'deleteComment',
	deleteProject = 'deleteProject',
	getLikes = 'getLikes',
	getRelatedCollections = 'getRelatedCollections',
	getRelatedProjects = 'getRelatedProjects',
	getUserInfo = 'getUserInfo',
	like = 'like',
	liveSession = 'liveSession',
	pinComment = 'pinComment',
	pinProjectToProfile = 'pinProjectToProfile',
	publishProject = 'publishProject',
	reportComment = 'reportComment',
	reportProject = 'reportProject',
	suggestFeature = 'suggestFeature',
	unpublishProject = 'unpublishProject',
	updateCollection = 'updateCollection',
	updateProfileLink = 'updateProfileLink',
	updateProject = 'updateProject',
	updateUser = 'updateUser'
}

type RPCData<T> =
	T extends RPCEndpoint.adminAddShelf					? { tag: string; } :
	T extends RPCEndpoint.adminDeleteComment			? { commentId: string; projectId: string; } :
	T extends RPCEndpoint.adminDeleteProject			? { projectId: string; } :
	T extends RPCEndpoint.adminRemoveShelf				? { tag: string; } :
	T extends RPCEndpoint.adminShadowComment			? { commentId: string; projectId: string; } :
	T extends RPCEndpoint.adminShadowProject			? { projectId: string; } :
	T extends RPCEndpoint.addImageTask					? { projectId: string; format: ImageFormat; width: number; height: number; } :
	T extends RPCEndpoint.commentOnProject				? { projectId: string; message: string; } :
	T extends RPCEndpoint.createCheckoutSession			? { lookupKey: string; } :
	T extends RPCEndpoint.createPortalCheckoutSession	? {} :
	T extends RPCEndpoint.createCollection				? { title?: string } :
	T extends RPCEndpoint.createProject					? {} :
	T extends RPCEndpoint.createUser					? {} :
	T extends RPCEndpoint.deleteAccount					? {} :
	T extends RPCEndpoint.deleteCollection				? { collectionId: string; } :
	T extends RPCEndpoint.deleteComment					? { commentId: string; projectId: string; } :
	T extends RPCEndpoint.deleteProject					? { projectId: string; } :
	T extends RPCEndpoint.getLikes						? { id: string; type: 'collection' | 'comment' | 'profile' | 'project' } :
	T extends RPCEndpoint.getRelatedCollections			? { uid: string; } :
	T extends RPCEndpoint.getRelatedProjects			? { uid: string; } :
	T extends RPCEndpoint.getUserInfo					? { uids: Array<string>; } :
	T extends RPCEndpoint.like							? Partial<Like> :
	T extends RPCEndpoint.liveSession					? { projectId: string; operation: 'start' | 'stop'; } :
	T extends RPCEndpoint.pinComment					? { commentId: string; projectId: string; } :
	T extends RPCEndpoint.pinProjectToProfile			? { projectId: string; } :
	T extends RPCEndpoint.publishProject				? { projectId: string; } :
	T extends RPCEndpoint.reportComment					? { commentId: string; projectId: string; message?: string; } :
	T extends RPCEndpoint.reportProject					? { projectId: string; message?: string; } :
	T extends RPCEndpoint.suggestFeature				? { message: string; } :
	T extends RPCEndpoint.unpublishProject				? { projectId: string; } :
	T extends RPCEndpoint.updateCollection				? { collectionId: string; data: Partial<Collection>; } :
	T extends RPCEndpoint.updateProfileLink				? { link: string; } :
	T extends RPCEndpoint.updateProject					? { projectId: string; data: Partial<Project>; } :
	T extends RPCEndpoint.updateUser					? { data: Partial<AccountSettings> } :
	never;

type RPCResponse<T> =
	T extends RPCEndpoint.adminAddShelf					? RPCResponseObject :
	T extends RPCEndpoint.adminDeleteComment			? RPCResponseObject :
	T extends RPCEndpoint.adminDeleteProject			? RPCResponseObject :
	T extends RPCEndpoint.adminRemoveShelf				? RPCResponseObject :
	T extends RPCEndpoint.adminShadowComment			? RPCResponseObject<'cleared' | 'shadowed'> :
	T extends RPCEndpoint.adminShadowProject			? RPCResponseObject<'cleared' | 'shadowed'> :
	T extends RPCEndpoint.addImageTask					? RPCResponseObject<string> :
	T extends RPCEndpoint.commentOnProject				? RPCResponseObject :
	T extends RPCEndpoint.createCheckoutSession			? RPCResponseObject<string> :
	T extends RPCEndpoint.createPortalCheckoutSession	? RPCResponseObject<string> :
	T extends RPCEndpoint.createCollection				? RPCResponseObject<string> :
	T extends RPCEndpoint.createProject					? RPCResponseObject<string> :
	T extends RPCEndpoint.createUser					? RPCResponseObject :
	T extends RPCEndpoint.deleteAccount					? RPCResponseObject :
	T extends RPCEndpoint.deleteCollection				? RPCResponseObject :
	T extends RPCEndpoint.deleteComment					? RPCResponseObject :
	T extends RPCEndpoint.deleteProject					? RPCResponseObject :
	T extends RPCEndpoint.getLikes						? RPCResponseObject<undefined, Array<Like>> :
	T extends RPCEndpoint.getRelatedCollections			? RPCResponseObject<undefined, Array<string>> :
	T extends RPCEndpoint.getRelatedProjects			? RPCResponseObject<undefined, Array<string>> :
	T extends RPCEndpoint.getUserInfo					? RPCResponseObject<undefined, Record<string, PublicUserInfo>> :
	T extends RPCEndpoint.like							? RPCResponseObject<'added' | 'error' | 'removed'> :
	T extends RPCEndpoint.liveSession					? RPCResponseObject :
	T extends RPCEndpoint.pinComment					? RPCResponseObject<'error' | 'pinned' | 'unpinned'> :
	T extends RPCEndpoint.pinProjectToProfile			? RPCResponseObject :
	T extends RPCEndpoint.publishProject				? RPCResponseObject :
	T extends RPCEndpoint.reportComment					? RPCResponseObject :
	T extends RPCEndpoint.reportProject					? RPCResponseObject :
	T extends RPCEndpoint.suggestFeature				? RPCResponseObject :
	T extends RPCEndpoint.unpublishProject				? RPCResponseObject :
	T extends RPCEndpoint.updateCollection				? RPCResponseObject :
	T extends RPCEndpoint.updateProfileLink				? RPCResponseObject :
	T extends RPCEndpoint.updateProject					? RPCResponseObject :
	T extends RPCEndpoint.updateUser					? RPCResponseObject :
	never;

type RPCEndpointFunction<T extends RPCEndpoint> = (data: RPCData<T>) => Promise<RPCResponse<T>>;
type RPCEndpointMap = Partial<{ [K in RPCEndpoint]: RPCEndpointFunction<K> }>;

type RPCCallback<T> = (response: RPCResponse<T>) => void | Promise<void>;

export interface RPCOptions<T> {
	/** *(Optional)* A function to call when the RPC is complete. */
	callback?: RPCCallback<T>;
	/** *(Optional)* A function to call when the emulated RPC is complete. */
	emulatedCallback?: RPCCallback<T>;
}

/**
 * Make a remote procedure call.
 * @param endpoint The endpoint to call.
 * @param data The data to send to the endpoint.
 * @param options *(Optional)* Options to configure the call with.
 * @returns The result of the RPC.
 */
export async function RPC <T extends RPCEndpoint> (endpoint: T, data: RPCData<T>, options?: RPCOptions<T>): Promise<RPCResponse<T>> {
	try {
		const emulator = Emulator[endpoint];
		if (emulator) {
			const emulatedResponse = await emulator(data) as RPCResponse<T>;
			if (!emulatedResponse.success) return emulatedResponse;
			void options?.emulatedCallback?.(emulatedResponse);
		}
		const local = Local[endpoint];
		if (local) {
			const localResponse = await local(data) as RPCResponse<T>;
			if (!localResponse) throw new Error();
			void options?.callback?.(localResponse);
			return localResponse;
		} else {
			const response = (await httpsCallable(Firebase.functions, endpoint)(data)).data as RPCResponse<T>;
			if (!response) throw new Error();
			void options?.callback?.(response);
			return response;
		}
	} catch (error) {
		switch (endpoint) {
			case RPCEndpoint.getUserInfo:
				return {} as RPCResponse<T>;
			default:
				return RPCResponseLiteral.ServerError() as RPCResponse<T>;
		}
	}
}

RPC.Endpoint = RPCEndpoint;

const Emulator: RPCEndpointMap = {
	[RPCEndpoint.createProject]: async () => {
		if (!userStore.authenticated) return RPCResponseLiteral.AccountRequired('');
		if (!flagsStore.projects) return RPCResponseLiteral.Unavailable('');
		if (!userStore.subscribed && Firebase.projects.value.size >= maxAllowedFreeProjects) {
			return RPCResponseLiteral.SubscriptionRequired('');
		}
		return RPCResponseLiteral.Success('');
	},
	[RPCEndpoint.like]: async (data) => {
		if (!userStore.authenticated) return RPCResponseLiteral.AccountRequired('error');
		if (!flagsStore.likes) return RPCResponseLiteral.Unavailable('error');
		if (!data.collectionId && !data.commentId && !data.profileId && !data.projectId) return RPCResponseLiteral.BadRequest('error');
		if (data.commentId && !data.projectId) return RPCResponseLiteral.BadRequest('error');
		if (data.projectId) {
			const project = await Firebase.publicProjects.get(data.projectId);
			if (!project) return RPCResponseLiteral.NotFound('error');
		}
		try {
			let id = '';
			let type = '';
			if (data.collectionId) {
				id = data.collectionId;
				type = 'collection';
			} else if (data.profileId) {
				id = data.profileId;
				type = 'profile';
			} else if (data.commentId) {
				id = data.commentId;
				type = 'comment';
			} else if (data.projectId) {
				id = data.projectId;
				type = 'project';
			}
			if (!id || !type) return RPCResponseLiteral.BadRequest('error');
			const result = await getDocs(query(
				collection(Firebase.database, 'likes'),
				where('author', '==', userStore.uid),
				where(`${type}Id`, '==', id),
				limit(1)
			));
			const add = result.size === 0;
			return RPCResponseLiteral.Success(add ? 'added' : 'removed');
		} catch {
			return RPCResponseLiteral.ServerError('error');
		}
	},
	[RPCEndpoint.pinProjectToProfile]: async (data) => {
		if (!userStore.raw?.emailVerified) return RPCResponseLiteral.AccountVerificationRequired();
		if (!data.projectId) return RPCResponseLiteral.BadRequest();
		const project = await Firebase.publicProjects.get(data.projectId);
		if (!project) return RPCResponseLiteral.NotFound();
		if (project.owner !== userStore.uid) return RPCResponseLiteral.NotFound();
		if (!project.public) return RPCResponseLiteral.NotFound();
		const user = await Firebase.publicUsers.get(userStore.uid);
		if (!user) return RPCResponseLiteral.ServerError();
		user.pinnedProject = data.projectId;
		return RPCResponseLiteral.Success();
	}
};

const Local: RPCEndpointMap = {
	[RPCEndpoint.createCollection]: async (data) => {
		if (!userStore.uid) return RPCResponseLiteral.AccountRequired('');
		const newCollection = new Collection(userStore.uid);
		if (data.title) newCollection.title = data.title;
		try {
			const { id } = await addDoc(collection(Firebase.database, 'collections').withConverter(Collection), newCollection);
			return RPCResponseLiteral.Success(id);
		} catch {
			return RPCResponseLiteral.ServerError('');
		}
	},
	[RPCEndpoint.createUser]: async () => {
		if (!userStore.uid) return RPCResponseLiteral.AccountRequired();
		try {
			await setDoc(doc(Firebase.database, `users/${userStore.uid}`).withConverter(AccountSettings), new AccountSettings());
			return RPCResponseLiteral.Success();
		} catch {
			return RPCResponseLiteral.ServerError();
		}
	},
	[RPCEndpoint.deleteCollection]: async (data) => {
		const confirmation = window.confirm('Are you sure you want to delete this collection?');
		if (!confirmation) return RPCResponseLiteral.ClientCancelledRequest();
		if (!userStore.authenticated) return RPCResponseLiteral.BadRequest();
		if (!data.collectionId) return RPCResponseLiteral.BadRequest();
		const collection = await Firebase.publicCollections.get(data.collectionId);
		if (!collection) return RPCResponseLiteral.NotFound();
		if (collection.owner !== userStore.uid) return RPCResponseLiteral.NotFound();
		try {
			await deleteDoc(doc(Firebase.database, `collections/${data.collectionId}`));
			return RPCResponseLiteral.Success();
		} catch {
			return RPCResponseLiteral.ServerError();
		}
	},
	[RPCEndpoint.deleteComment]: async (data) => {
		const confirmation = window.confirm('Are you sure you want to delete this comment?');
		if (!confirmation) return RPCResponseLiteral.ClientCancelledRequest();
		if (!userStore.authenticated) return RPCResponseLiteral.BadRequest();
		if (!data.projectId || !data.commentId) return RPCResponseLiteral.BadRequest();
		const comments = await Firebase.publicComments.get(data.projectId);
		const comment = comments?.find(comment => comment.id === data.commentId);
		if (!comment) return RPCResponseLiteral.NotFound();
		if (comment.comment.author !== userStore.uid) return RPCResponseLiteral.NotFound();
		try {
			await deleteDoc(doc(Firebase.database, `projects/${data.projectId}/comments/${data.commentId}`));
			return RPCResponseLiteral.Success();
		} catch {
			return RPCResponseLiteral.ServerError();
		}
	},
	[RPCEndpoint.deleteProject]: async (data) => {
		const confirmation = window.confirm('Are you sure you want to delete this project?');
		if (!confirmation) return RPCResponseLiteral.ClientCancelledRequest();
		if (!userStore.authenticated) return RPCResponseLiteral.BadRequest();
		if (!data.projectId) return RPCResponseLiteral.BadRequest();
		const project = await Firebase.publicProjects.get(data.projectId) ?? Firebase.projects.value.get(data.projectId);
		if (!project) return RPCResponseLiteral.NotFound();
		if (project.owner !== userStore.uid) return RPCResponseLiteral.NotFound();
		try {
			await deleteDoc(doc(Firebase.database, `projects/${data.projectId}`));
			if (Firebase.projects.value.size <= 0) {
				const response = await RPC(RPC.Endpoint.createProject, {});
				if (response.success && response.message) {
					onlineProject.select(response.message);
				}
			}
			return RPCResponseLiteral.Success();
		} catch {
			return RPCResponseLiteral.ServerError();
		}
	},
	[RPCEndpoint.getLikes]: async ({ id, type }) => {
		try {
			const result = await getDocs(query(
				collection(Firebase.database, 'likes').withConverter(Like),
				where(`${type}Id`, '==', id)
			));
			const likes = result.docs.map(document => document.data());
			return RPCResponseLiteral.Success(undefined, likes);
		} catch {
			return RPCResponseLiteral.ServerError(undefined, []);
		}
	},
	[RPCEndpoint.updateCollection]: async ({ collectionId, data }) => {
		if (!userStore.authenticated) return RPCResponseLiteral.AccountRequired();
		if (!collectionId) return RPCResponseLiteral.BadRequest();
		const collection = Firebase.collections.value.get(collectionId);
		if (collection) Object.assign(collection, data);
		for (const [property, value] of Object.entries(data)) {
			Firebase.collectionBatcher.write(collectionId, property, value);
		}
		return RPCResponseLiteral.Success();
	},
	[RPCEndpoint.updateProject]: async ({ projectId, data }) => {
		if (!userStore.authenticated) return RPCResponseLiteral.AccountRequired();
		if (!projectId) return RPCResponseLiteral.BadRequest();
		const project = Firebase.projects.value.get(projectId);
		if (!project) return RPCResponseLiteral.NotFound();
		Object.assign(project, data);
		for (const [property, value] of Object.entries(data)) {
			if (property === 'css' || property === 'compressedCss') {
				Firebase.projectBatcher.write(projectId, 'css', project.compressedCss);
				continue;
			}
			Firebase.projectBatcher.write(projectId, property, value);
		}
		return RPCResponseLiteral.Success();
	},
	[RPCEndpoint.updateUser]: async ({ data }) => {
		if (!userStore.uid) return RPCResponseLiteral.AccountRequired();
		if (!Firebase.settings.value || !data) return RPCResponseLiteral.ServerError();
		Object.assign(Firebase.settings.value, data);
		const document = doc(Firebase.database, `users/${userStore.uid}`);
		try {
			await updateDoc(
				document,
				{
					biography: Firebase.settings.value.biography,
					theme: Firebase.settings.value.theme
				}
			);
			return RPCResponseLiteral.Success();
		} catch {
			return RPCResponseLiteral.ServerError();
		}
	}
};
