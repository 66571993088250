<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import confetti from 'canvas-confetti';
import { userStore } from '@/main';
import { RPC } from '@/modules/firebase/rpc';
import { wall } from '@/modules/firebase/walls';
import { Like } from '@/structures';
import Icon from '@/components/Icon.vue';

const props = defineProps({
	confetti: { type: Boolean, required: false },
	project: { type: String, required: false }
});

let confettiCannon: confetti.CreateTypes | undefined;
const confettiCanvas = ref<HTMLCanvasElement | null>(null);
const confettiConfig = {
	angle: 90,
	colors: ['#ff69b4'],
	disableForReducedMotion: true,
	gravity: 0.2,
	origin: { x: 0.5, y: 0.5 },
	particleCount: 50,
	scalar: 0.5,
	spread: 360,
	startVelocity: 8,
	ticks: 50,
	zIndex: 1
};
const formatter = Intl.NumberFormat('en', { notation: 'compact' });
const likeAllowed = ref(true);
const likes = ref(new Array<Like>());
const liked = computed(() => likes.value.some(like => like.author === userStore.uid));
const count = computed(() => likes.value.length === 0 ? '' : formatter.format(likes.value.length));

async function load (): Promise<void> {
	if (!props.project) return;
	const response = await RPC(RPC.Endpoint.getLikes, { id: props.project, type: 'project' });
	if (response.success) {
		likes.value = response.data;
	}
}

async function like (): Promise<void> {
	wall.message = 'Login to like this project.';
	if (!wall.authenticated()) return;
	if (!likeAllowed.value || !props.project || !userStore.uid) return;
	likeAllowed.value = false;
	const like = Like.project(userStore.uid, props.project);
	await RPC(
		RPC.Endpoint.like,
		like,
		{
			emulatedCallback: (response) => {
				if (response.message === 'added') {
					likes.value.push(like);
					if (props.confetti) confettiCannon?.(confettiConfig);
				} else if (response.message === 'removed') {
					const index = likes.value.findIndex(a => a.author === like.author && a.projectId === like.projectId);
					likes.value.splice(index, 1);
				}
			}
		}
	);
	likeAllowed.value = true;
}

watch(() => props.project, load, { immediate: true });

onMounted(() => {
	if (confettiCanvas.value) {
		confettiCannon = confetti.create(confettiCanvas.value);
	}
});
</script>

<template>
	<button
		class="like"
		:class="{ liked }"
		:disabled="!likeAllowed"
		title="Like"
		@click="like()"
		data-journey-id="ProjectCardLike"
	>
		<canvas ref="confettiCanvas" v-show="props.confetti"></canvas>
		<Icon :class="{ outline: !liked }">favorite</Icon>
		<span v-show="count">{{ count }}</span>
	</button>
</template>

<style scoped>
button.like {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: large;
	border: unset;
}
button.like.liked:hover {
	background-color: #ff69b440;
}

button.like > canvas {
	position: absolute;
	top: calc(-100px + 1em);
	left: calc(-125px + 1em);
	width: 250px;
	height: 200px;
	mask-image: radial-gradient(closest-side at center, black 80%, transparent);
	opacity: 0.6;
	user-select: none;
	pointer-events: none;
}

button.like.liked > span:nth-of-type(1) {
	color: hotpink;
	transform-origin: center;
	animation: heartbeat 0.4s linear forwards;
}

button.like > span:nth-of-type(2) {
	min-width: 1ch;
	margin-right: 0.4ch;
	font-size: small;
	line-height: 1em;
}

@keyframes heartbeat {
	0%   { transform: scale(1); }
	35%  { transform: scale(1.4); }
	50%  { transform: scale(1); }
	85%  { transform: scale(1.3); }
	100% { transform: scale(1); }
}
</style>
