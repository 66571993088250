import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import type { User } from 'firebase/auth';
import Firebase from '@/modules/firebase/core';
import { Role, SubscriptionTier } from '@/structures';

export const useUserStore = defineStore('user', () => {
	const adminMode = ref(false);
	const authenticated = computed(() => Boolean(raw.value));
	const autoLogin = Firebase.auth.authStateReady();
	const displayName = computed(() => raw.value?.displayName);
	const link = ref<string>('');
	const raw = ref<User | null>(null);
	const role = ref(Role.None);
	const subscribed = computed(() => tier.value === SubscriptionTier.Premium);
	const tier = ref(SubscriptionTier.Free);
	const uid = computed(() => raw.value?.uid);
	const vanityLink = ref<string>('');

	function set (user: User | null): void {
		raw.value = user;
	}

	function reset (): void {
		adminMode.value = false;
		role.value = Role.None;
		tier.value = SubscriptionTier.Free;
		link.value = '';
		vanityLink.value = '';
	}

	watch(raw, async user => {
		// If the user is not signed in, set their tier to free and clear their role.
		if (!user?.uid) {
			reset();
			return;
		}
		// Otherwise, fetch the user's info.
		const userInfo = await Firebase.publicUsers.get(user.uid);
		// If the user is a moderator or admin, set their tier to premium.
		role.value = userInfo?.role ?? Role.None;
		if (role.value >= Role.Moderator) {
			tier.value = SubscriptionTier.Premium;
		} else {
			tier.value = userInfo?.tier ?? SubscriptionTier.Free;
		}
	}, { deep: true });

	return {
		adminMode,
		authenticated,
		autoLogin,
		displayName,
		link,
		raw,
		reset,
		role,
		set,
		subscribed,
		tier,
		uid,
		vanityLink
	};
});
