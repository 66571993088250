<script setup lang="ts">
import { onlineProject } from '@/main';
import Firebase from '@/modules/firebase/core';
import Icon from '@/components/Icon.vue';
import ProjectLink from '@/components/ProjectLink.vue';

const emit = defineEmits(['new', 'settings']);
</script>

<template>
	<aside class="projectLinks">
		<div class="projectLinksInner">
			<button
				:disabled="Firebase.loading.value"
				title="New project"
				@click="emit('new')"
				data-journey-id="CreateProjectLinksNew"
			>
				<span v-if="Firebase.loading.value" class="spinner"></span>
				<Icon v-else>add</Icon>
			</button>
			<ul>
				<li
					v-for="[id, project], index of Firebase.projects.value"
					:key="id"
					:style="{ animationDelay: `calc(0.02s * ${index})` }"
				>
					<ProjectLink
						:title="project.title"
						:date="project.dateEdited"
						:public="project.public"
						:loading="Firebase.loading.value"
						:selected="id === onlineProject.id"
						@click.stop="onlineProject.select(id)"
						@settings="emit('settings', id)"
					/>
				</li>
			</ul>
		</div>
	</aside>
</template>

<style scoped>
.projectLinks {
	grid-area: aside;
	display: flex;
	flex-flow: column nowrap;
	width: 0;
	height: 100%;
	margin-right: 0;
	border-radius: var(--border-radius-large);
	opacity: 0;
	transition:
		0.4s ease width,
		0.4s ease height,
		0.4s ease margin,
		0.4s ease opacity;
	overflow: hidden;
	user-select: none;
	pointer-events: none;
}
main.showProjectLinks .projectLinks {
	width: var(--aside-width);
	margin-right: var(--gap);
	opacity: 1;
	user-select: revert;
	pointer-events: all;
}

.projectLinksInner {
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5rem;
	width: 100%;
	height: 100%;
	padding: 0.5rem 0.5rem 1rem;
	background-color: var(--color-background);
	border-radius: inherit;
	overflow-y: auto;
}
.projectLinksInner::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: inherit;
	background: radial-gradient(circle at 50% 0, #fff, transparent);
	opacity: 0.02;
	pointer-events: none;
}

.projectLinksInner::-webkit-scrollbar {
	width: 0.5em;
	height: 0.5em;
}
.projectLinksInner::-webkit-scrollbar-track {
	background: var(--color-background);
}
.projectLinksInner::-webkit-scrollbar-track:horizontal {
	background: var(--color-background);
}
.projectLinksInner::-webkit-scrollbar-thumb {
	background: var(--color-border-light);
	border-radius: 0.5em;
}
.projectLinksInner::-webkit-scrollbar-thumb:horizontal {
	background: var(--color-border-light);
	border-radius: 0.5em;
}
.projectLinksInner::-webkit-scrollbar-thumb:hover {
	background: var(--color-border);
}

.projectLinks ul {
	z-index: 1;
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5rem;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
}
.projectLinks ul > li {
	display: flex;
	opacity: 0;
	transform: translateX(-30px);
	animation: projectLinkTransitionIn 0.15s ease forwards;
}

@keyframes projectLinkTransitionIn {
	0%   { opacity: 0; transform: translateX(-30px); }
	100% { opacity: 1; transform: translateX(0); }
}

@media only screen and (max-width: 80rem) {
	.projectLinks {
		width: 100%;
		height: 0;
		overflow: hidden;
	}
	main.showProjectLinks .projectLinks {
		width: 100%;
		height: var(--aside-height);
		margin-right: 0;
	}
	.projectLinksInner {
		flex-flow: row nowrap;
		padding: 0.5rem;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.projectLinks ul {
		flex-flow: row nowrap;
	}
	.projectLink {
		grid-template-columns: min-content 12ch min-content;
	}
}
</style>
