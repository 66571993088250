<script setup lang="ts">
import { ref } from 'vue';
import { dialogStore, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import { wall } from '@/modules/firebase/walls';
import { RPCResponse } from '@/structures';
import Icon from '@/components/Icon.vue';

defineProps({
	noHeading: { type: Boolean, required: false },
	wide: { type: Boolean, required: false }
});

const loadingSubscription = ref(false);

async function subscribe (): Promise<void> {
	if (!wall.multiple([wall.authenticated, wall.verified])) return;
	loadingSubscription.value = true;
	const lookupKey = Firebase.config.value?.premiumProductLookupKey;
	if (!lookupKey) {
		loadingSubscription.value = false;
		return;
	}
	const response = await RPC(RPC.Endpoint.createCheckoutSession, { lookupKey });
	if (response.status === RPCResponse.SeeOther().status) {
		let link = response.message!;
		if (userStore.raw?.email) {
			link += '?prefilled_email=' + userStore.raw.email;
		}
		window.open(link, '_blank');
	} else if (response.status === RPCResponse.AccountRequired().status) {
		wall.multiple([wall.authenticated, wall.verified]);
	} else {
		dialogStore.open(dialogStore.Dialog.Notification, 'Something went wrong, please try again in a few minutes.');
	}
	loadingSubscription.value = false;
}

async function manage (): Promise<void> {
	loadingSubscription.value = true;
	const response = await RPC(RPC.Endpoint.createPortalCheckoutSession, {});
	if (response.status === RPCResponse.SeeOther().status) {
		window.open(response.message, '_blank');
	} else if (response.status === RPCResponse.AccountRequired().status) {
		wall.multiple([wall.authenticated, wall.verified]);
	} else if (response.status === RPCResponse.SubscriptionRequired().status) {
		await subscribe();
	} else {
		dialogStore.open(dialogStore.Dialog.Notification, 'Something went wrong, please try again in a few minutes.');
	}
	loadingSubscription.value = false;
}
</script>

<template>
	<div
		class="premiumCard"
		:class="{
			noHeading,
			subscribed: userStore.subscribed,
			wide
		}"
		data-journey-id="PremiumCard"
	>
		<div class="lighting"></div>
		<div class="top">
			<h3>Premium</h3>
			<p><span>$5</span><span>per month</span></p>
		</div>
		<div class="middle">
			<p>Thanks for subscribing!&nbsp;❤️</p>
			<p>You've got access to:</p>
		</div>
		<div class="bottom">
			<ul>
				<li><Icon>all_inclusive</Icon>Unlimited projects</li>
				<li><Icon>link</Icon>Custom profile link</li>
				<li><Icon>cards</Icon>Component downloads</li>
				<li><Icon>campaign</Icon>Priority feature suggestions</li>
			</ul>
		</div>
		<div></div>
		<button
			v-if="userStore.subscribed"
			class="lightHover"
			:disabled="loadingSubscription"
			@click="manage()"
			data-journey-id="PremiumCardManage"
		>
			<span v-if="loadingSubscription" class="spinner"></span>
			<span>Manage Subscription</span>
		</button>
		<button
			v-else
			class="lightHover"
			:disabled="loadingSubscription"
			@click="subscribe()"
			data-journey-id="PremiumCardSubscribe"
		>
			<span v-if="loadingSubscription" class="spinner"></span>
			<span>Subscribe</span>
		</button>
	</div>
</template>

<style scoped>
.premiumCard {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 0fr auto 0fr auto;
	place-content: center;
	place-items: center;
	gap: 1rem;
	width: 100%;
	max-width: 270px;
	padding: 1rem 1.5rem;
	color: white;
	background-color: var(--color-premium);
	border: 3px solid #ffffff2a;
	border-radius: var(--border-radius);
	box-shadow: 0 2px 25px -15px #000c;
	transition: 0.2s ease grid-template-rows;
}
.premiumCard.wide {
	max-width: unset;
	font-size: large;
}
.premiumCard.subscribed {
	grid-template-rows: 0fr 0.9fr auto 0.1fr auto;
}

.premiumCard::before,
.premiumCard::after {
	content: '✨';
	position: absolute;
	font-size: 2rem;
	text-shadow: 0 2px 5px #2221;
}
.premiumCard::before {
	top: -1.1rem;
	left: -1.3rem;
	transform: rotate(-20deg);
}
.premiumCard::after {
	right: -1.2rem;
	bottom: 2.5rem;
	transform: rotate(20deg);
}

.premiumCard > .lighting {
	position: absolute;
	inset: 0;
	background: radial-gradient(circle at 50% -20%, #fff, transparent 80%);
	pointer-events: none;
	opacity: 0.2;
}

.premiumCard > .top,
.premiumCard > .middle,
.premiumCard > .bottom {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: inherit;
	width: 100%;
	height: 100%;
	min-height: 0;
	opacity: 1;
	visibility: visible;
	overflow: hidden;
	transition: 0.2s ease opacity, 0.2s ease visibility;
}
.premiumCard > .top,
.premiumCard > .middle {
	text-align: center;
}

.premiumCard.subscribed > .top {
	opacity: 0;
	visibility: hidden;
}

.premiumCard > .middle {
	opacity: 0;
	visibility: hidden;
	overflow: visible;
}
.premiumCard.subscribed > .middle {
	opacity: 1;
	visibility: visible;
}

.premiumCard > .middle > p {
	margin: 0;
}
.premiumCard > .middle > p:nth-of-type(1) {
	padding: 1em 1.5ch;
	font-size: larger;
	background-color: #ffffff1a;
	border: 3px solid #ffffff20;
	border-radius: var(--border-radius);
}

.premiumCard > .top > h3 {
	font-size: x-large;
}
.premiumCard.noHeading > .top > h3 {
	display: none;
}

.premiumCard > .top > p {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	width: 100%;
	margin: 0;
	padding: 2rem 0.5rem;
	text-align: center;
	background-color: #ffffff1a;
	border: 3px solid #ffffff20;
	border-radius: var(--border-radius);
}
.premiumCard > .top > p span:nth-of-type(1) {
	font-size: 3.5rem;
}
.premiumCard > .top > p span:nth-of-type(2) {
	width: fit-content;
	padding: 0 1ch;
	font-size: small;
	background-color: var(--color-premium);
	border-radius: var(--border-radius);
}

.premiumCard > .bottom > ul {
	display: flex;
	flex-flow: column nowrap;
	gap: 1ch;
	padding-inline-start: 0.5ch;
	list-style: none;
}
.premiumCard > .bottom li {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 1ch;
}

.premiumCard > button {
	margin-top: auto;
	padding: 0.2em 1ch 0.3em;
	font-size: inherit;
	border: 1px solid currentColor;
}
.premiumCard > button:hover {
	background-color: #fff3;
}
.premiumCard > button > span {
	font-size: 1em;
}
</style>
